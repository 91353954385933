import React from 'react';
import { PictureParagraph, PrimaryText } from '../components';
import { Box } from '@mui/system';
import Container from 'components/Container';
import { TabTitle } from './Styles';
import FirstImage from 'images/Properties/market/01.svg';
import SecondImage from 'images/Properties/market/02.svg';
import ThirdImage from 'images/Properties/market/03.svg';
import Disclaimer from '../components/Disclaimer';

const MarketTab = (): JSX.Element => {
  const PrimaryTextContent = {
    aboutMarket: {
      id: 1,
      title: '',
      anchor: 'market',
      content: [
        'Vejle is a city in Denmark located in Jutland next to the Vejle Fjord. Vejle is part of the Southern region of Denmark and is governed by Vejle municipality (kommune). The population of Vejle city consists of 60,231 residents and greater Vejle has a population of 120,361. Vejle is the fifth largest city in Denmark and has significant industries and companies located in and around the city. The German border is 110 km from Vejle, while the Danish capital, Copenhagen, is 220 km away.',
        // eslint-disable-next-line quotes
        `Historically, Vejle has had an extensive cotton mill industry, however, more recently Vejle is attracting the technology industry. Numerous software and service companies are located in the city, including Siemens Gamesa renewable energy. On the outskirts of Vejle, there are dozens of warehouses. In the Northern part of the Vejle, there are many factories including one of the largest meat production factories, Tulip, and one of the largest chewing gum factories producing Stimorol chewing gum and other products like liquorice. Vejle's convenient geographic location allows it to easily access other towns like Billund, Kolding, Horsens, and Fredericia, which are home to many large Danish corporations like LEGO.`,
        'Vejle has a strong educational presence with 11 primary education institutions, 5 private schools, 5 secondary institutions, and multiple adult and professional education institutions. Higher education is also represented in the city by the University College Lillebælt and UCL Erhvervsakademi og Professionshøjskole.',
        'The population growth in Vejle Kommune is stable, leading to increasing demand for rental properties. Based on statistics from Statistics Denmark (dst.dk), the population has grown in Vejle municipality from 106,383 residents in 2010 to 120,361 residents in the third quarter of 2022.',
      ],
    },
    propertyMarket: {
      id: 2,
      title: 'Vejle property market',
      anchor: '',
      content: [
        'The Vejle property market, consistent with the rest of Denmark, has stable performance and experienced growth over the past years.  Below is the price index statistics for property sales in the Southern Denmark region.',
      ],
    },
    rentalPrices: {
      id: 3,
      title: '',
      anchor: '',
      content: [
        'As previously mentioned, the Vejle population has experienced stable growth, and a demand for rental real estate has driven an increase in rental prices. In the time period from 2015 to 2021, the average yearly square meter rent price has increased from € 104 to € 122.',
      ],
    },
  };

  const pictureParagraphContent = {
    growthInVejle: {
      id: 1,
      title: 'Population growth in Vejle kommune',
      altTxt: 'Source: Statistics Denmark',
      alt: 'Graph of statistics describing population growth in Danish town Velje',
      imageSrc: FirstImage,
    },
    priceIndex: {
      id: 1,
      title: 'Price index for sales of property (2006=100)',
      altTxt: 'Source: Statistics Denmark',
      alt: 'Price index for sales of real estate properties in Syddanmark region Danmark',
      imageSrc: SecondImage,
    },
    averageRentalPrice: {
      id: 1,
      title: 'Average yearly m² rental price',
      altTxt: 'Source: Statistics Denmark',
      alt: 'Graph of average yearly m2 rental price increase in Denmarks real estate',
      imageSrc: ThirdImage,
    },
  };

  return (
    <Container
      display="flex"
      sx={{
        flexDirection: { md: 'column', sm: 'column', xs: 'column' },
        paddingTop: { md: '14px', sm: '14px', xs: '14px' },
        margin: 0,
      }}
    >
      <TabTitle>Market</TabTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', sm: 'column', xs: 'column' },
          gap: '52px',
          paddingTop: '58px',
        }}
      >
        <Box>
          <PrimaryText content={PrimaryTextContent.aboutMarket} />
          <PictureParagraph content={pictureParagraphContent.growthInVejle} />
        </Box>
        <Box
          sx={{
            marginTop: { md: '-48px', sm: 'initial', xs: 'initial' },
          }}
        >
          <PrimaryText content={PrimaryTextContent.propertyMarket} />
          <PictureParagraph content={pictureParagraphContent.priceIndex} />
          <PrimaryText
            content={PrimaryTextContent.rentalPrices}
            sx={{ paddingTop: '84px' }}
          />
          <PictureParagraph
            content={pictureParagraphContent.averageRentalPrice}
          />
        </Box>
      </Box>
      <Disclaimer />
    </Container>
  );
};

export default MarketTab;
