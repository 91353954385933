import React from 'react';
import { SxProps, DefaultTheme } from '@mui/system';
import PrimaryText from '../PrimaryText';

type Props = {
  sx?: SxProps<DefaultTheme>;
};

const Disclaimer = ({ sx }: Props): JSX.Element => {
  const disclaimer = {
    id: 4,
    title: '',
    anchor: '',
    content: [
      'Please be aware that the information on this page regarding market data and industry forecasts was obtained from industry publications and has not been independently verified by the Company. The images provided are project illustrations therefore the final result may vary. It is important to note that the data presented may be subject to assumptions and limitations. Prospective investors are advised not to rely solely on this information and should conduct their own research before making any investment decisions. ',
    ],
  };

  return (
    <PrimaryText
      content={disclaimer}
      sx={{
        maxWidth: 'unset',
        paddingTop: '84px',
        textAlign: 'center',
        '.primaryText-infoText': { fontSize: '0.755rem' },
      }}
    />
  );
};

export default Disclaimer;
